/**
 * Created by TapFun on 2019/3/1.
 */
import axios from 'axios';
import {SERVER_HOST} from './../common/portConfig'

var instance = axios.create({
  headers: {
    'Authorization':''
  }
});

instance.interceptors.request.use(
  config => {
    config.headers.Authorization = sessionStorage.getItem('Authorization');
    return config
  }, err => {
    return Promise.reject(err)
  });

//用户列表
export const reqWebUserPage = params => {
  return instance.get(`${SERVER_HOST}/admin/wechatUser/page`, {params: params})
};









