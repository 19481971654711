<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
                <el-form :inline="true" :model="filters">
                    <el-form-item>
                        <el-input v-model="filters.nickName" placeholder="请输入用户昵称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="getWebUserPage" icon="search">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-table :data="webUsers" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
                <!--<el-table-column type="selection" width="50" align="center"></el-table-column>-->
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="code" label="编号" width="120" align="center"></el-table-column>
                <el-table-column prop="avatar" label="头像" width="120" align="center">
                    <template slot-scope="scope">
                        <el-avatar v-if="scope.row.avatar" :src="scope.row.avatar"></el-avatar>
                        <el-avatar v-else icon="el-icon-user-solid"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="nickName" label="昵称" min-width="180" align="center"></el-table-column>
                <el-table-column prop="gender" label="性别" width="120" align="center">
                    <template slot-scope="scope">
                        <span class="view-text">{{scope.row.gender==='2'?'女':scope.row.gender==='1'?'男':'未知'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="country" label="国家" width="120" align="center"></el-table-column>
                <el-table-column prop="province" label="省份" width="120" align="center"></el-table-column>
                <el-table-column prop="city" label="城市" width="150" align="center"></el-table-column>
                <el-table-column prop="openid" label="微信标识" min-width="250" align="center"></el-table-column>
                <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable></el-table-column>
            </el-table>
            <el-col :span="24" class="table-footer">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes=[10,15,20,25,50] :page-size="pageSize" :current-page.sync="pageNum" layout="total, sizes, prev, pager, next, jumper" :total="total" style="float:right;">
                </el-pagination>
            </el-col>
        </el-col>
    </el-row>
</template>
<script>
    import {reqWebUserPage} from '../../api/weChatUser-api'

    export default {
        data() {
            return {
                filters: {
                    nickName: ''
                },
                webUsers: [],
                total: 0,
                pageNum: 1,
                pageSize: 15,
                listLoading: false,
                sels: [],
            }
        },

        methods: {
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getWebUserPage();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getWebUserPage();
            },
            //重置
            goReset() {
                this.filters.nickName = '';
                this.pageNum = 1;
                this.getWebUserPage();
            },

            selsChange: function (sels) {
                this.sels = sels;
            },
            getWebUserPage() {
                var that = this;
                that.listLoading = true;
                let para = {
                    pageNum: that.pageNum,
                    pageSize: that.pageSize,
                    nickName: that.filters.nickName,
                };
                reqWebUserPage(para).then((res) => {
                    if (res.data.code === 1) {
                        that.total = res.data.data.total;
                        that.webUsers = res.data.data.records;
                    } else {
                        that.$message({
                            message: '查询失败',
                            type: 'error'
                        });
                    }
                    that.listLoading = false;
                }).catch(() => {
                    that.$message({
                        message: '查询失败',
                        type: 'error'
                    });
                })
            },
        },
        mounted() {
            this.getWebUserPage();
        }
    }
</script>
